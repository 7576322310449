//import logo from './logo.svg';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Container from 'react-bootstrap/Container';
import {Carousel} from 'react-responsive-carousel';

import 'bootstrap/dist/css/bootstrap.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import './App.css';

const start = new Date();
const end = new Date(2022, 7, 21);

console.log("Start: " + start.toString());
console.log("End: " + end.toString());

var now = Math.round((end-start)/(1000*60*60*24));

console.log(now);

now = Math.round((1 - (now / 273)) * 100);

console.log(now);

//now = now + 10;

function App() {
    return (
	    <div className="App">
	      <header className="App-header">
                <p>Bloomington - Ragusa Family - Preparing for new Player...</p>
	        <img src="potato.png" height="30%" width="30%" />
                <p>Loading new Player</p>
                <Container style={{ width: '50%' }}>
                  <ProgressBar animated striped variant="success" now={now} label={`${now}% Complete`} style={{ height: '60px'}} />
                </Container>
  	        <p>Estimated Completion August 21 2022</p>
	    <Carousel autoPlay={true} showThumbs={false} width={650} infiniteLoop={true} interval={10000}>
                  <div>
                    <img src="baby/1.jpeg"/>
                    <p className="legend">Looking for my home!</p>
                  </div>
                  <div>
                    <img src="baby/2.jpeg" />
                    <p className="legend">Just moved into my new home for 9months!</p>
                  </div>
                  <div>
                    <img src="baby/3.jpeg" />
                    <p className="legend">Wait... am I a bean?</p>
                  </div>
	          <div>
	            <img src="baby/4.png" />
	            <p className="legend">Wiggle Worm</p>
               	  </div>
                  <div>
                    <img src="baby/5.png" />
                    <p className="legend">Peakaboo!</p>
                  </div>
	          <div>
                    <img src="baby/6.png" />
                    <p className="legend">Trying to bust on out</p>
                  </div>
	          <div>
                    <img src="baby/7.png" />
                    <p className="legend">Thumbs up!</p>
                  </div>
	          <div>
                    <img src="baby/8.png" />
                    <p className="legend">First Wave at the camera!</p>
                  </div>
	          <div>
                    <img src="baby/9.png" />
                    <p className="legend">&#10084;&#65039;</p>
                  </div>
	          <div>
                    <img src="baby/10.png" />
                    <p className="legend">Ready to Walk</p>
                  </div>
	          <div>
                    <img src="baby/11.png" />
                    <p className="legend">Profile Pick</p>
                  </div>
                </Carousel>
              </header>
            </div>
  );
}

export default App;
